<template>
  <CContainer>
  <CRow>
    <CCol col="12">
      <CCard>
        <CCardHeader>
            <strong>Precio Cliente Usuario {{user.name}}</strong>
          </CCardHeader>
          <CCardBody>
               <CContainer>
                 <CRow class="align-items-center">
                   <CCol col="6">
                     <CSelect
                        label="Agregar un Tipo de Envio"
                        :options="listaPrecioSms"
                        :value.sync="tipoEnvioadd"
                      />
                   </CCol>
                   <CCol col="2">
                     <CButton color="success" @click="agregarPrecioUsuario">Agregar</CButton>
                   </CCol>
                 </CRow>
               </CContainer>
               <CContainer>
                  <table class="table">
                     <thead>
                       <tr>
                         <td><strong>Pais</strong></td>
                         <td><strong>Codigo Pais</strong></td>
                         <td><strong>Tipo Envio</strong></td>
                         <td><strong>Ruta Sms</strong></td>
                         <td><strong>Precio Cliente Sms</strong></td>
                         <td><strong>Accion</strong></td>
                       </tr>
                     </thead>  
                     <tbody>
                       <tr v-for="(item, key) in listaPrecioCliente">
                         <td>{{item.pais}}</td>
                         <td>{{item.id_pais}}</td>
                         <td>{{item.tipo_envio}}</td>
                         <td>
                            <CSelect
                              :ref="key"
                              :options="listaRutas"
                              :value.sync="ruta_select = item.id_ruta"
                            />
                         </td>
                         <td>
                           <CInput  
                              :ref="key"
                              :value="item.precio_compra_usuario_final"
                            >
                            </CInput>
                         </td>
                         <td>
                           <CButton color="primary" v-on:click="actualizarPrecioUsuario($event,key,item.id_pais,item.tipo_envio)" v-if="$can('usuario.precio_cliente')">Guardar</CButton>
                           <CButton color="danger"  v-on:click="abrirmodalEliminarPrecioSms(item.id_pais,item.tipo_envio,item.iduser)" v-if="$can('usuario.precio_cliente')">ELiminar</CButton>
                         </td>
                       </tr>
                     </tbody>
                  </table>
               </CContainer>
          </CCardBody>
        <!--<CCardFooter>
          
        </CCardFooter>-->
      </CCard>
    </CCol>
  </CRow>
         <div>
          <CToaster :autohide="5000" class="d-inline-block">
             <template v-for="toast in mostrarNotificacion">
                <CToast
                  :key="'toast'+toast"
                  :show="true"
                  color="success"
                  header="Notificacion Exitosa"
                >
                  {{mensaje_success}}
                </CToast>
              </template>   
              <template v-for="toast1 in mostrarNotificacionAlerta">
                <CToast
                  :key="'toast1'+toast1"
                  :show="true"
                  color="warning"
                  header="Notificacion Alerta"
                >
                  {{mensaje_warning}}
                </CToast>
              </template>
              <template v-for="toast2 in mostrarNotificacionError">
                <CToast
                  :key="'toast2'+toast2"
                  :show="true"
                  color="danger"
                  header="Notificacion Alerta"
                >
                  Error Comunicarse Con Soporte
                </CToast>
              </template>  
          </CToaster>
        </div>
        <div>
          <CModal
            :show.sync="modal_eliminar_precio_sms"
            :no-close-on-backdrop="true"
            :centered="true"
            title="Modal title 2"
            size="sm"
            color="success"
          >
          <template #header>
              <h6 class="modal-title">Eliminar Precio Sms</h6>
              <CButtonClose @click="modal_eliminar_precio_sms = false" class="text-white"/>
            </template>
            <CRow>
              <CCol col="12">
                  <p>Esta seguro de Eliminar Precio Sms Para Este Usuario</p>
              </CCol>
            </CRow>
            <template #footer>
              <CButton @click="modal_eliminar_precio_sms = false" color="danger">Cancelar</CButton>
              <CButton v-on:click.stop="eliminarPrecioUsuario" color="success">Aceptar</CButton>
            </template>
          </CModal>
        </div>
  </CContainer>
</template>

<script>
export default {
  name: 'PrecioCliente',
  data () {
    return {
      mostrarNotificacion: 0,
      mostrarNotificacionAlerta: 0,
      mostrarNotificacionError:0,
      mensaje_warning:'Debe Completar Los Datos Vacio',
      mensaje_success:'Datos Guardado Correctamente.',
      user:{ 
        name:''
      },
      listaPrecioCliente:[],
      listaRutas:[],
      ruta_select:null,
      tipoEnvioadd:'',
      listaPrecioSms:[],
      form_elimina:{
        id_pais:null,
        tipo_envio:null,
        iduser:null,
      },
      modal_eliminar_precio_sms:false,
    }
  },
  methods: {
    goBack() {
      this.usersOpened ? this.$router.go(-1) : this.$router.push({path: '/roles'})
    },
    obtenerPrecioUsuarioPorId () {
        const id = this.$route.params.id
        this.$store.dispatch('auth/loading_http','visible');
        this.$http.get('/sms/usuarios/precio/cliente/'+id).then(response => {
            this.user = response.data.usuarios;
            this.listaPrecioCliente = response.data.listaPrecio;
            this.listaRutas = response.data.rutas_sms;
            this.listaPrecioSms = response.data.precio_sms;
        }).catch((e)=>{}).finally(() =>this.$store.dispatch('auth/loading_http','hidden'));
    },
    validator (val) {
      return val ? val.length >= 4 : false
    },
    actualizarPrecioUsuario(evt,key,id_pais,tipo_envio){
        const id = this.$route.params.id
        let id_ruta = this.$refs[key][0].state;
        let precio = this.$refs[key][1].state;
        this.$store.dispatch('auth/loading_http','visible');
        this.$http.post('/sms/usuarios/precio/cliente/'+id,{
            id_pais:id_pais,
            id_ruta:id_ruta,
            precio:precio,
            tipo_envio:tipo_envio,
        })
        .then(response => {
          if (response.data == 'OK') {
            this.mensaje_success='Datos Guardado Correctamente.'
            this.mostrarNotificacion ++;
            this.obtenerPrecioUsuarioPorId();
          }else if(response.data == 'Error'){
              this.mensaje_warning = `Ya Se Encuentra Actualizado Los Datos del Pais ${id_pais}`;
              this.mostrarNotificacionAlerta += 1;
          }else{
            let mensajeError = '';
              let dataError = response.data;
              for (const key in dataError) {
                if (Object.hasOwnProperty.call(dataError, key)) {
                  const element = dataError[key];
                  for (const iterator of element) {
                    mensajeError += `${iterator} \n`
                  }
                }
              }
              this.mensaje_warning = mensajeError
              this.mostrarNotificacionAlerta += 1;
          }
        }).catch((error)=>{this.mostrarNotificacionError ++;}).finally(() =>this.$store.dispatch('auth/loading_http','hidden'));
    },
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
      //if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();;
      } else {
        return true;
      }
    },
    agregarPrecioUsuario(){
        if (this.tipoEnvioadd == '') {
            this.mensaje_warning = `Debe Seleccionar una Opcion para Agregarla`;
            this.mostrarNotificacionAlerta += 1;
            return false;
        }
        const id = this.$route.params.id
        this.$store.dispatch('auth/loading_http','visible');
        this.$http.post('/sms/usuarios/precio/cliente/agrega/'+id,{
            tipo:'agregar',
            agregar:this.tipoEnvioadd,
        })
        .then(response => {
          if (response.data == 'OK') {
            this.mensaje_success='Datos Guardado Correctamente.'
            this.mostrarNotificacion ++;
            this.obtenerPrecioUsuarioPorId();
          }else{
            let mensajeError = '';
              let dataError = response.data;
              for (const key in dataError) {
                if (Object.hasOwnProperty.call(dataError, key)) {
                  const element = dataError[key];
                  for (const iterator of element) {
                    mensajeError += `${iterator} \n`
                  }
                }
              }
              this.mensaje_warning = mensajeError
              this.mostrarNotificacionAlerta += 1;
          }
        }).catch((error)=>{this.mostrarNotificacionError ++;}).finally(() =>this.$store.dispatch('auth/loading_http','hidden'));
    },
    eliminarPrecioUsuario(){
        this.modal_eliminar_precio_sms = false;
        const id = this.$route.params.id
        this.$store.dispatch('auth/loading_http','visible');
        this.$http.post('/sms/usuarios/precio/cliente/agrega/'+id,{
            tipo:'eliminar',
            id_pais:this.form_elimina.id_pais,
            tipo_envio:this.form_elimina.tipo_envio,
            iduser:this.form_elimina.iduser,
        })
        .then(response => {
          if (response.data == 'OK') {
            this.mensaje_success='Datos Eliminados Correctamente.'
            this.mostrarNotificacion ++;
            this.obtenerPrecioUsuarioPorId();
          }else{
            let mensajeError = '';
              let dataError = response.data;
              for (const key in dataError) {
                if (Object.hasOwnProperty.call(dataError, key)) {
                  const element = dataError[key];
                  for (const iterator of element) {
                    mensajeError += `${iterator} \n`
                  }
                }
              }
              this.mensaje_warning = mensajeError
              this.mostrarNotificacionAlerta += 1;
          }
        }).catch((error)=>{this.mostrarNotificacionError ++;}).finally(() =>this.$store.dispatch('auth/loading_http','hidden'));
    },
    abrirmodalEliminarPrecioSms(id_pais,tipo_envio,iduser){
      this.modal_eliminar_precio_sms = true;
      this.form_elimina.id_pais = id_pais;
      this.form_elimina.tipo_envio = tipo_envio;
      this.form_elimina.iduser = iduser;
    }
  },
  mounted () {
      setTimeout(() => {
        this.obtenerPrecioUsuarioPorId();
      }, 100);
  },
}
</script>
